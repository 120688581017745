import { Ref } from 'react';
import {
  Animated,
  Platform,
  View,
  TextInput as NativeTextInput,
  TextInputProps,
} from 'react-native';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import {
  TextInputMask,
  TextInputMasked,
  TextInputMaskProps,
  TextInputMaskTypeProp,
} from 'react-native-masked-text';

import Text from '../Text';
import { containerWidth } from '../dimension.helper';
import { Color, TextStyle, ThemeProps, em } from '../theme';
import {
  colorFromTheme,
  platformSpecific,
  styledWithoutProps,
} from '../theme/helper';
import { AnimatedTextStyle } from './helper';

export interface SharedTextInputProps extends ThemeProps {
  focused: boolean;
  onBottom: boolean;
  floatingPlaceholder: boolean;
  onError: boolean;
  innerReference?: Ref<NativeTextInput | TextInputMasked>;
  withLargeText?: boolean;
  borderLess: boolean;
}

interface StyledMaskTextInputProps
  extends SharedTextInputProps,
    Omit<TextInputMaskProps, 'type'> {
  type?: TextInputMaskTypeProp;
}

interface StyledTextInputProps extends SharedTextInputProps, TextInputProps {}

function borderColor({
  theme,
  focused,
  onError,
  borderLess,
}: SharedTextInputProps): string {
  if (onError) return theme.color[Color.DANGER];
  if (borderLess) return 'transparent';

  return focused ? theme.color[Color.PRIMARY] : theme.color[Color.GRAY];
}

/** Container */
export const Container = styled(View)`
  width: ${containerWidth};
`;

const sharedTextInputStyle = css<SharedTextInputProps>`
  height: ${em(3)};
  width: 100%;
  border-color: ${borderColor};
  color: ${colorFromTheme(Color.DARK)};

  ${platformSpecific.web`
    box-sizing: border-box;
    font-size: 1em;

    ${({
      theme,
      onBottom,
      focused,
    }: SharedTextInputProps): FlattenSimpleInterpolation => css`
      padding: ${focused
        ? `0 calc(${theme.spacing.medium} - ${theme.border.small})`
        : `0 ${theme.spacing.medium}`};
      padding-top: ${onBottom ? '1em' : '0'};
      border-width: ${focused ? theme.border.medium : theme.border.small};
      border-radius: ${theme.radius.small};
    `}

    :focus {
      outline: none;
    }
  `}

  ${platformSpecific.mobile`
    border-top-color: transparent;

    ${({
      theme,
      focused,
      withLargeText,
    }: SharedTextInputProps): FlattenSimpleInterpolation => css`
      padding: ${em(withLargeText ? 0.25 : 1.125)} 0
        ${em(withLargeText ? 0.25 : 0.5)} 0;
      height: ${em(withLargeText ? 5.5 : 3)};
      font-size: ${em(withLargeText ? 4 : 1, { forFont: true })};
      text-align: ${withLargeText ? 'center' : 'left'};

      border-top-width: ${focused ? theme.border.medium : theme.border.small};
      border-bottom-width: ${focused
        ? theme.border.medium
        : theme.border.small};
    `}
  `}
`;

/** Text input */
export const StyledMaskTextInput = styledWithoutProps<StyledMaskTextInputProps>(
  TextInputMask,
  'focused',
  'onBottom',
  'floatingPlaceholder',
  'onError',
)`
  ${sharedTextInputStyle}
`;

export const StyledTextInput = styledWithoutProps<StyledTextInputProps>(
  NativeTextInput,
  'focused',
  'onBottom',
  'floatingPlaceholder',
  'onError',
)`
  ${sharedTextInputStyle}
`;

/** Floating placeHolder */
export interface FloatingPlaceHolderProps extends ThemeProps {
  isOnTop: boolean;
  style: AnimatedTextStyle;
  placeholderTextColor?: string;
}

export const FloatingPlaceHolder = styled(Animated.Text)<
  FloatingPlaceHolderProps
>`
  position: absolute;

  ${platformSpecific.web`
    pointer-events: none;
  `}

  ${platformSpecific.mobile`
    left: 0;
    ${({ isOnTop }: FloatingPlaceHolderProps): string =>
      isOnTop ? `letter-spacing: ${em(0.025)}` : ''}
  `}
`;

export const HelperContainer = styled(View)`
  margin-top: ${em(0.5)};
`;

export const HelperText = styled(Text)``;

HelperText.defaultProps = {
  styleKey: Platform.OS === 'web' ? TextStyle.BODY_3 : TextStyle.BODY_2,
  color: Color.GRAY_500,
};
